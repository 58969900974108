import React from "react"
import { Link } from "gatsby"

export default props => (
  // <div className="gallery-card"
  //   style={{
  //     backgroundImage: `url(${props.img})`,
  //   }}
  // >
  //     <div className="gallery-title-div">
  //       <h2 className="gallery-title">
  //         {props.title}
  //       </h2>
  //     </div>
  // </div>
  <article
    className={`gallery-card with-image`}
    style={{
      backgroundImage: `url(${props.img})`,
    }}
  >
    <Link to={props.link} className="gallery-card-link">
      <div className="gallery-card-content">
        <h2 className="gallery-card-title">{props.title}</h2>
      </div>
    </Link>
  </article>
)
