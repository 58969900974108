import "../../utils/normalize.css"
import "../../utils/css/screen.css"

import { StaticQuery, graphql } from "gatsby"

import Exhibition from "./G404.png"
import Decoration from "./G305.jpg"
import GalleryCard from "../../components/galleryCard"
import PostCard from "../../components/postCard"
import Layout from "../../components/layout"
import Painting from "./G206.jpg"
import React from "react"
import SEO from "../../components/seo"
import Sculpture from "./G1h1.jpg"

const GalleryPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title
  return (
    <Layout title={siteTitle}>
      <SEO
        title="Gallery"
        keywords={[`sculpture`, `painting`, `art`, `design`]}
      />

      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <h3 id="dynamic-styles">gallery</h3>
        </div>
        <div className="row">
          <div className="col-6">
            <GalleryCard
              link={`/gallery/painting`}
              img={Painting}
              title="paintings"
            />
          </div>
          <div className="col-6">
            <GalleryCard
              link={`/gallery/sculpture`}
              img={Sculpture}
              title="sculputres"
            />
          </div>
          <div className="col-6">
            <GalleryCard
              link={`/gallery/decoration`}
              img={Decoration}
              title="decorations"
            />
          </div>
          <div className="col-6">
            <GalleryCard
              link={`/gallery/exhibition`}
              img={Exhibition}
              title="art exhibitions"
            />
          </div>
        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    benchAccounting: file(
      relativePath: { eq: "bench-accounting-49909-unsplash.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { eq: "painting" } } }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            thumbnail {
              childImageSharp {
                fluid(maxWidth: 1360) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <GalleryPage location={props.location} data={data} {...props} />
    )}
  />
)
